import { useTranslations } from 'next-intl'
import styled from 'styled-components'
import { CardMainText } from '@nordic-web/ui-components'

const PlayingOverlay = styled.div(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  background: theme.color.surface.black70,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  pointerEvents: 'none',
}))

export function CardPlayingOverlay() {
  const t = useTranslations()
  return (
    <PlayingOverlay>
      <CardMainText>{t('labels__playing_now')}</CardMainText>
    </PlayingOverlay>
  )
}
