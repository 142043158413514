import { useIsTableau } from '@/features/channel-epg-panel'
import { TableauMetaTags } from '@/features/channel-epg-panel/tableau-meta-tags'
import { TableauSeoH1 } from '@/features/channel-epg-panel/tableau-seo-h1'
import { PageMetaTags } from '@/features/page/page-meta-tags'
import { PagePanelsFetcher } from '@/features/page/page-panels-fetcher'
import { NordicApp } from '@/layouts/app'
import type { Page } from '@/pages/types'

export const CHANNELPAGE_ID = 'channels'

const ChannelsPage: Page = () => {
  const isTableau = useIsTableau()

  return (
    <>
      {isTableau ? (
        <>
          <TableauSeoH1 />
          <TableauMetaTags pageId={CHANNELPAGE_ID} />
        </>
      ) : (
        <PageMetaTags pageId={CHANNELPAGE_ID} />
      )}
      <PagePanelsFetcher id={CHANNELPAGE_ID} showBreadcrumbs={false} />
    </>
  )
}

ChannelsPage.getLayout = (Page, props) => {
  return (
    <NordicApp shouldOverrideHeaderMarginBehaviour>
      <Page {...props} />
    </NordicApp>
  )
}

export default ChannelsPage
