import type { ComponentPropsWithoutRef, PropsWithChildren, TransitionEventHandler } from 'react'
import { useEffect, useRef } from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import { belowTablet } from '@nordic-web/ui-styles'
import { DialogBody, DialogDrawerBody } from './dialog-body'
import { DialogContext } from './dialog-context'
import { DialogImageTop, DialogTop } from './dialog-top'

export type DialogProps = PropsWithChildren<
  {
    nwInitOpen?: boolean
    className?: string
    nwVariant?: 'stacked' | 'stacked-small' | 'drawer' | 'drawer-small'
    nwCloseOnBackdropClick?: boolean
    dataTestId?: string
  } & ComponentPropsWithoutRef<'dialog'>
>

const transitionInClass = 'transition-in'

const DialogInternal = ({
  nwInitOpen,
  className,
  nwVariant,
  children,
  dataTestId,
  nwCloseOnBackdropClick = true,
  ...forwardProps
}: DialogProps) => {
  const dialogRef = useRef<HTMLDialogElement>(null)

  useEffect(() => {
    if (nwInitOpen) {
      handleOpen()
    } else {
      handleClose()
    }
  }, [nwInitOpen])

  const handleOpen = () => {
    dialogRef.current?.showModal()
    dialogRef.current?.classList.add(transitionInClass)
  }

  const handleClose = () => {
    dialogRef.current?.classList.remove(transitionInClass)
    /**
     * When nwInitOpen is quickly toggled back and forth, the handleTransitionEnd handler might not be called.
     * This will force the dialog to close if nwInitOpen is false and it shouldn't interfere with the closing
     * animations since the order of execution during a standard close call is:
     * handleClose -> handleTransitionEnd -> useEffect.
     */
    dialogRef.current?.close()
  }

  const handleTransitionEnd: TransitionEventHandler = () => {
    if (dialogRef.current?.classList.contains(transitionInClass)) return
    dialogRef.current?.close()
  }

  const handleBackdropClick = (event: Event) => {
    if (nwCloseOnBackdropClick && event.target === dialogRef.current) {
      handleClose()
    }
  }

  useEffect(() => {
    const dialogElement = dialogRef.current
    dialogElement?.addEventListener('click', handleBackdropClick)
    return () => dialogElement?.removeEventListener('click', handleBackdropClick)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.attributeName === 'open') {
          const isOpen = dialogRef.current?.hasAttribute('open')
          if (isOpen) {
            // Hack for safari
            setTimeout(() => dialogRef.current?.classList.add(transitionInClass), 0)
          }
        }
      })
    })

    if (dialogRef.current) {
      observer.observe(dialogRef.current, { attributeFilter: ['open'] })
    }

    return () => {
      observer.disconnect()
    }
  }, [])

  return (
    <DialogContext.Provider value={{ handleClose }}>
      <StyledDialog
        ref={dialogRef}
        className={className}
        nwVariant={nwVariant}
        {...forwardProps}
        data-testid={dataTestId}
        onTransitionEnd={handleTransitionEnd}
      >
        {nwInitOpen && <BodyScrollLock />}
        {children}
      </StyledDialog>
    </DialogContext.Provider>
  )
}

const BodyScrollLock = createGlobalStyle({
  body: {
    overflow: 'hidden',
  },
})

const StyledDialog = styled.dialog<Pick<DialogProps, 'nwVariant'>>(({ theme, nwVariant = 'stacked' }) => ({
  boxShadow: `0 10px 10px 10px ${theme.color.surface.black20}`,
  color: 'unset',
  flexDirection: 'column',
  position: 'fixed',
  background: theme.color.base.background,
  maxWidth: 'unset',
  maxHeight: 'unset',
  padding: 0,
  border: 'none',
  borderRadius: theme.radii.border_radius_large,
  '&[open]': {
    display: 'flex',
  },
  ...((nwVariant === 'stacked' || nwVariant === 'stacked-small') && {
    maxHeight: `calc(100% - ${theme.space(10)})`,
    minHeight: 372,
    transition: 'opacity .25s ease-out',
    opacity: 0,
    [`&.${transitionInClass}`]: {
      opacity: 1,
    },
  }),
  ...(nwVariant === 'stacked' && {
    width: `min(calc(100% - ${theme.space(10)}), 704px)`,
  }),
  ...(nwVariant === 'stacked-small' && {
    width: `min(calc(100% - ${theme.space(10)}), 372px)`,
  }),
  ...((nwVariant === 'drawer' || nwVariant === 'drawer-small') && {
    height: '100%',
    marginRight: 0,
    marginBlock: 0,
    borderRadius: 0,
    transition: 'transform 0.25s ease-out',
    transform: 'translateX(100%)',
    [`&.${transitionInClass}`]: {
      transform: 'translateX(0%)',
    },
  }),
  ...(nwVariant === 'drawer-small' && {
    width: 280,
  }),
  ...(nwVariant === 'drawer' && {
    width: theme.space(128),
    [belowTablet]: {
      width: `min(100%, 372px)`,
    },
  }),
  '&::backdrop': {
    background: theme.color.surface.black50,
  },
}))

export const Dialog = Object.assign(DialogInternal, {
  Top: DialogTop,
  ImageTop: DialogImageTop,
  DrawerBody: DialogDrawerBody,
  Body: DialogBody,
})
