import React from 'react'
import { useTranslations } from 'next-intl'
import styled from 'styled-components'
import { Button } from '@nordic-web/ui-components'
import { TypographyText, TypographyTitle } from '@nordic-web/ui-components'
import { useLogout } from '@/hooks/use-logout'

const ErrorText = styled(TypographyText)(({ theme }) => ({
  color: theme.color.text.error,
}))

export const LogoutOthersConfirm = ({ onCancelLogoutOther }: { onCancelLogoutOther: () => void }) => {
  const t = useTranslations()
  const { logoutAll, isLoggingOutAll, logoutAllError } = useLogout()

  return (
    <>
      <TypographyTitle as="h2" nwPreamble={t('log_out_devices__description')}>
        {t('log_out_devices__headline')}
      </TypographyTitle>
      {logoutAllError && <ErrorText nwVariant="smallText">{t('error_message__unknown_error')}</ErrorText>}
      <Button onClick={() => logoutAll()} nwLoading={isLoggingOutAll} nwLayout="full-width">
        {t('log_out_devices__yes')}
      </Button>
      <Button onClick={onCancelLogoutOther} nwVariant="ghost" nwLayout="full-width">
        {t('log_out_devices__no')}
      </Button>
    </>
  )
}
