import type {
  CoreAssetClickEvent,
  CoreAssetImpressionEvent,
  CorePageEvent,
  CorePanelClickEvent,
  CorePanelImpressionEvent,
} from '@nordic-web/tracking'
import {
  coreAssetClickSchema,
  coreAssetImpressionSchema,
  corePageSchema,
  corePanelClickSchema,
  corePanelImpressionSchema,
} from '@nordic-web/tracking'
import { getPageName } from '@/helpers/get-page-name'
import { sessionId } from '@/lib/apollo/init-apollo'
import { getSnowplowProfileId } from '@/tracking/snowplow/get-snowplow-profile-id'
import { track } from '@/tracking/snowplow/snowplow'

type SharedFields = 'profile_id' | 'session_id' | 'page_name'

const defaultCoreTrackingFields = () => {
  return {
    session_id: sessionId,
    profile_id: getSnowplowProfileId(),
    page_name: getPageName() || '',
  }
}

export const trackPage = (data: Omit<CorePageEvent, SharedFields>) =>
  track({ ...data, ...defaultCoreTrackingFields() }, corePageSchema.$schema)
export const trackAssetImpression = (data: Omit<CoreAssetImpressionEvent, SharedFields>) =>
  track({ ...data, ...defaultCoreTrackingFields() }, coreAssetImpressionSchema.$schema)
export const trackAssetClick = (data: Omit<CoreAssetClickEvent, SharedFields>) =>
  track({ ...data, ...defaultCoreTrackingFields() }, coreAssetClickSchema.$schema)
export const trackPanelImpression = (data: Omit<CorePanelImpressionEvent, SharedFields>) =>
  track({ ...data, ...defaultCoreTrackingFields() }, corePanelImpressionSchema.$schema)
export const trackPanelClick = (data: Omit<CorePanelClickEvent, SharedFields>) =>
  track({ ...data, ...defaultCoreTrackingFields() }, corePanelClickSchema.$schema)
