import React from 'react'
import type { PageQuery } from '@nordic-web/gql'
import { usePageQuery } from '@nordic-web/gql'
import { FullPageCircleSpinner } from '@nordic-web/ui-components'
import { nonNullable } from '@nordic-web/utils/typescript'
import { CategoriesBreadcrumbs } from '@/components/categories-breadcrumbs/categories-breadcrumbs'
import { PageMargin } from '@/components/scaffolding/page-margin'
import { getSwipeModuleApiLimit } from '@/components/swipe-module/swipe-module'
import { ErrorHandler } from '@/layouts/error-handler'
import { usePageCacheLogic } from './hooks/use-page-cache-logic'
import { PanelList } from './panel-list'

export const PAGE_PANEL_FETCH_LIMIT = 8

type PagePanelsFetcherProps = {
  id: string
  renderMetaTags?: (page: PageQuery) => React.JSX.Element | null
  showBreadcrumbs: boolean
  topLeftContent?: React.ReactNode
  adjustHeaderMargins?: boolean
}

export const PagePanelsFetcher = ({
  id,
  renderMetaTags,
  showBreadcrumbs,
  topLeftContent,
  adjustHeaderMargins = true,
}: PagePanelsFetcherProps) => {
  usePageCacheLogic(id)

  const {
    data: newData,
    previousData,
    loading: isLoading,
    error,
    fetchMore,
  } = usePageQuery({
    ssr: false,
    variables: {
      pageId: id,
      input: {
        limit: PAGE_PANEL_FETCH_LIMIT,
        offset: 0,
      },
      offset: 0,
      limit: getSwipeModuleApiLimit(),
    },
  })

  // This is needed to avoid the loading state when switching page in the sub page menu
  const data = newData ?? previousData

  const nextPageInfo = data?.page?.content.pageInfo

  if (isLoading && !data) {
    return <FullPageCircleSpinner />
  }

  if (!data || error) {
    return <ErrorHandler error={error} statusCode={500} />
  }

  if (!data?.page?.id || !nextPageInfo) {
    return <ErrorHandler statusCode={404} />
  }

  const page = data.page
  const pageTitle = page?.title

  const getTopLeftContent = () => {
    if (showBreadcrumbs) {
      return (
        <PageMargin>
          <CategoriesBreadcrumbs categoryName={pageTitle} />
        </PageMargin>
      )
    }

    return topLeftContent
  }

  return (
    <>
      {/* This data attribute is used to create cms links in cms-link.tsx */}
      <span data-page-id={data.page.id} />

      {renderMetaTags?.(data)}
      <PanelList
        adjustHeaderMargins={adjustHeaderMargins}
        topLeftContent={getTopLeftContent()}
        loadMoreItems={() =>
          fetchMore({
            variables: {
              input: {
                limit: PAGE_PANEL_FETCH_LIMIT,
                offset: nextPageInfo.nextPageOffset,
              },
            },
          })
        }
        nextPageInfo={nextPageInfo}
        panels={data.page.content.panels.filter(nonNullable)}
      />
    </>
  )
}
