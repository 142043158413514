import { QueryClient } from '@tanstack/react-query'
import { isServerSide } from '@nordic-web/utils/misc/detect-side'

let queryClient: QueryClient
export const createQueryClient = () => {
  // Reuse the same query client on the client side, but on server side we never want to reuse it between requests
  if (!queryClient || isServerSide) {
    queryClient = new QueryClient({
      defaultOptions: {
        queries: {
          // Dont cache on the server side, it can cause memory leaks
          ...(isServerSide && {
            staleTime: 0,
            cacheTime: 0,
          }),
          refetchOnWindowFocus: false,
          retry: false,
          refetchOnReconnect: false,
        },
      },
    })
  }
  return queryClient
}
