import { parseJwt } from '@nordic-web/utils/authentication/token'
import { getCurrentTimeDiff, getServerTime } from '@nordic-web/utils/date/get-server-time'

type Result = {
  isExpired: boolean
  usedServerTime?: number
  expiresAt?: number
  timeDiff?: number
}

export const isTokenExpired = (token?: string): Result => {
  if (!token) {
    return { isExpired: false }
  }

  const parsedJwt = parseJwt(token)

  if (!parsedJwt) {
    return { isExpired: false }
  }

  const { exp } = parsedJwt

  const now = Math.floor(getServerTime().getTime() / 1000)

  return {
    isExpired: now > exp,
    usedServerTime: now,
    expiresAt: exp,
    timeDiff: getCurrentTimeDiff(),
  }
}
