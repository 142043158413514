import React, { useMemo } from 'react'
import { useTranslations } from 'next-intl'
import styled from 'styled-components'
import type { ShortListFieldsFragment } from '@nordic-web/gql'
import { type ClipFieldsLightFragment, MediaClassification } from '@nordic-web/gql'
import { Label } from '@nordic-web/ui-components'
import {
  CardContainer,
  CardFooterContainer,
  CardFooterTextContainer,
  CardImageContainer,
  CardMainText,
  CardOverlayContainer,
  CardSecondaryText,
} from '@nordic-web/ui-components'
import type { OnCardClick } from '@/components/cards/types'
import { DotRow } from '@/components/dot-row'
import { FadeInImage } from '@/components/fade-in-image'
import { IsPlaying } from '@/components/is-playing/is-playing'
import { TruncateLines } from '@/components/truncate-lines/truncate-lines'
import { paths } from '@/helpers/paths'
import { AssetTracking } from '@/tracking/asset-tracking'

type ClipCardProps = {
  videoAsset: ClipFieldsLightFragment | ShortListFieldsFragment
  onCardClick?: OnCardClick
  panelId?: string
}

export const ClipCard = ({ videoAsset, onCardClick, panelId }: ClipCardProps) => {
  const t = useTranslations()
  const isClip = videoAsset.__typename === 'Clip'
  const href = isClip
    ? paths.clips.urlString({ playlist: panelId, assetId: videoAsset.id, slug: videoAsset.slug })
    : paths.shorts.urlString({ id: videoAsset.id, slug: videoAsset.slug })

  const isCategorizedAsNews = isClip && videoAsset.mediaClassification === MediaClassification.News

  const imageSource = useMemo(() => {
    if (isClip) {
      return videoAsset.images.main16x9.sourceEncoded
    }

    const firstFrame16x9 = videoAsset.images.firstFrame16x9
    return firstFrame16x9.isFallback ? videoAsset.images.firstFrame9x16.sourceEncoded : firstFrame16x9.sourceEncoded
  }, [isClip, videoAsset])

  const handleClick = () => {
    if (onCardClick) {
      onCardClick(videoAsset)
    }
  }

  return (
    <AssetTracking content_media_id={videoAsset.id}>
      {({ trackOnAssetClick }) => (
        <CardContainer
          href={href}
          data-testid="clip"
          onClick={() => {
            handleClick()
            trackOnAssetClick()
          }}
        >
          <CardImageContainer>
            <FadeInImage alt={videoAsset.title} source={imageSource} />
            <IsPlaying assetId={videoAsset.id} />
            <CardOverlayContainer>
              {isCategorizedAsNews && (
                <ClipLabel nwVariant="announcement">
                  {videoAsset.parent?.title || t('card__clip__news_label')}
                </ClipLabel>
              )}
              {!isClip && <ClipLabel nwVariant="announcement">{t('shorts__go_to_shorts')}</ClipLabel>}
              <CardMainText>
                <TruncateLines lines={3}>{videoAsset.title}</TruncateLines>
              </CardMainText>
            </CardOverlayContainer>
          </CardImageContainer>

          <CardFooterContainer nwMarginLeft>
            <CardFooterTextContainer>
              <CardSecondaryText>
                <DotRow items={[videoAsset.duration.readableShort, videoAsset.playableFrom?.readableDistance]} />
              </CardSecondaryText>
            </CardFooterTextContainer>
          </CardFooterContainer>
        </CardContainer>
      )}
    </AssetTracking>
  )
}

const ClipLabel = styled(Label)(({ theme }) => ({
  borderRadius: theme.radii.radiiX(1),
}))
