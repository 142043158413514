import type { ImageFieldsLightFragment } from '@nordic-web/gql'
import { isPlaying } from '@nordic-web/utils/epg'
import type { LiveCardAsset } from '@/components/cards/live-card/live-card'

type ParseResult = {
  title: string
  playableFrom?: {
    isoString: string
    readableDateTime: string
  } | null
  logo?: string
  startTime?: string
  endTime?: string
  playerPosterImage: string
  cardImage: string
  brandLogo?: ImageFieldsLightFragment | null
}

export const parseLiveCardData = (item: LiveCardAsset): ParseResult => {
  switch (item.__typename) {
    case 'Channel': {
      const playingEpgItem = item.epg?.find(isPlaying)
      const hasEpgImage = playingEpgItem && !playingEpgItem?.images.main16x9.isFallback

      return {
        title: playingEpgItem?.title ?? item.title,
        logo: item.images.logo?.sourceEncoded,
        endTime: playingEpgItem?.end,
        startTime: playingEpgItem?.start,
        playerPosterImage: hasEpgImage ? playingEpgItem.images.main16x9.source : item.images.main16x9.source,
        cardImage: hasEpgImage ? playingEpgItem.images.main16x9.sourceEncoded : item.images.main16x9.sourceEncoded,
      }
    }
    case 'Episode': {
      return {
        title: item.series?.title ?? item.title,
        playableFrom: item.playableFrom,
        startTime: item.playableFrom?.isoString,
        endTime: item.liveEventEnd?.isoString,
        playerPosterImage: item.images.main16x9.source,
        cardImage: item.images.main16x9.sourceEncoded,
      }
    }
    case 'Movie': {
      return {
        title: item.title,
        playableFrom: item.playableFrom,
        startTime: item.playableFrom?.isoString,
        endTime: item.liveEventEnd?.isoString,
        playerPosterImage: item.images.main16x9.source,
        cardImage: item.images.main16x9.sourceEncoded,
        brandLogo: item.images.brandLogo,
      }
    }
    case 'SportEvent': {
      return {
        title: item.title,
        playableFrom: item.playableFrom,
        startTime: item.playableFrom?.isoString,
        endTime: item.liveEventEnd?.isoString,
        playerPosterImage: item.images.main16x9.source,
        cardImage: item.images.main16x9.sourceEncoded,
        brandLogo: item.images.brandLogo,
      }
    }
  }
}
