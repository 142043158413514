import React from 'react'
import { ProgressBar, Stack, TypographyText } from '@nordic-web/ui-components'
import { useProgressQuery } from '@/hooks/progress/use-progress-query'

type ProgressBarWithQueryProps = {
  videoAssetId: string
  title?: string
}

export const ProgressBarWithQuery = ({ videoAssetId, title }: ProgressBarWithQueryProps) => {
  const { progress } = useProgressQuery(videoAssetId)

  const percent = progress?.percent

  if (percent) {
    return (
      <Stack nwGap={2}>
        {title && <TypographyText nwVariant="meta">{title}</TypographyText>}
        <ProgressBar percentage={percent} />
      </Stack>
    )
  }

  return null
}
