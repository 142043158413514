import Head from 'next/head'
import { BRAND, Brand } from '@/config/brand'

const Mtv = (
  <>
    <link rel="icon" sizes="32x32" type="image/x-icon" href="/static/mtv/favicon.ico" />
    <link rel="shortcut icon" type="image/svg+xml" href="/static/mtv/favicon.svg" />
    <link rel="apple-touch-icon" href="/static/mtv/apple-touch-icon.png" />
    <link href="/static/mtv/manifest.json" rel="manifest" />
  </>
)

const Tv4 = (
  <>
    <link
      rel="icon"
      sizes="48x48"
      type="image/x-icon"
      media="(prefers-color-scheme: dark)"
      href="/static/tv4/favicon-dark.ico"
    />
    <link
      rel="shortcut icon"
      type="image/svg+xml"
      media="(prefers-color-scheme: dark)"
      href="/static/tv4/favicon-dark-v2.svg"
    />
    <link
      rel="icon"
      sizes="48x48"
      type="image/x-icon"
      media="(prefers-color-scheme: light)"
      href="/static/tv4/favicon-light.ico"
    />
    <link
      rel="shortcut icon"
      type="image/svg+xml"
      media="(prefers-color-scheme: light)"
      href="/static/tv4/favicon-light-v2.svg"
    />

    <link rel="apple-touch-icon" href="/static/tv4/apple-touch-icon.png" />
    <link href="/static/tv4/manifest.json" rel="manifest" />

    <meta content="130928726959073" property="fb:app_id" />
    <meta content="app-id=413776071" name="apple-itunes-app" />
  </>
)

const map = {
  [Brand.MTV]: Mtv,
  [Brand.TV4]: Tv4,
}

export const AppMetaTags = () => {
  return <Head>{map[BRAND]}</Head>
}
