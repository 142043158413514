import type { FromSchema } from 'json-schema-to-ts'
import { schemas } from './generated/schemas'

export const endScreenAutoplayNextOptionalContentClickSchema =
  schemas['tvmedia/end_screen_autoplay_next_optional_content_click/jsonschema/1-0-0']
export const endScreenImpressionSchema = schemas['tvmedia/end_screen_impression/jsonschema/1-0-0']

export type EndScreenAutoplayNextOptionalContentClickEvent = Omit<
  FromSchema<typeof endScreenAutoplayNextOptionalContentClickSchema>,
  'app_version'
>
export type EndScreenImpressionEvent = Omit<FromSchema<typeof endScreenImpressionSchema>, 'app_version'>
