import { useSyncExternalStore } from 'react'
import Bugsnag from '@bugsnag/js'
import { createAuthenticationStore } from '@nordic-web/authentication/store/authentication-store'
import { getTldFromHostname } from '@nordic-web/utils/authentication/get-tld-from-hostname'
import { getServerTime } from '@nordic-web/utils/date/get-server-time'
import { isClientSide, isServerSide } from '@nordic-web/utils/misc/detect-side'
import { brandConfig } from '@/config/brand'
import {
  REFRESH_COOKIE_NAME,
  getRefreshTokenFromCookie,
  removeRefreshTokenFromCookie,
} from '@/features/auth/tokens/token-utils'
import type { TokenPair } from '@/features/auth/types'
import { setCookie, withDefaultCookieOptions } from '@/utils/cookies'
import { deepUnknownEqual } from '@/utils/deep-unknown-equal'
import { safeLocalStorageGetItem, safeLocalStorageRemoveItem, safeLocalStorageSetItem } from '@/utils/local-storage'

declare global {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface Window {
    authenticationTokens?: TokenPair
  }
}

const oneYearInSeconds = 60 * 60 * 24 * 365
const profileIdKey = 'profileId'
const tierOverrideIdKey = 'tierOverrideId'

// In the future we should consider doing a client side logout without reloading the page
export function logout() {
  removeRefreshTokenFromCookie()
  safeLocalStorageRemoveItem(profileIdKey)
  window.location.href = '/'
}

export const authenticationStore = createAuthenticationStore(
  {
    getToken: async () => {
      if (isServerSide) return
      return getRefreshTokenFromCookie()
    },
    removeToken: async () => {
      if (isServerSide) return
      logout()
    },
    setToken: async (token) => {
      if (isServerSide) return
      setCookie(
        null,
        REFRESH_COOKIE_NAME,
        token,
        withDefaultCookieOptions({
          maxAge: oneYearInSeconds,
          sameSite: 'lax',
          domain: getTldFromHostname(window.location.hostname),
        })
      )
    },
    getProfileId: async () => {
      if (isServerSide) return
      const profileId = safeLocalStorageGetItem(profileIdKey)
      return profileId ?? undefined
    },
    setProfileId: async (value) => {
      if (isServerSide) return
      safeLocalStorageSetItem(profileIdKey, value)
    },
    setTierOverrideId: async (value) => {
      if (isServerSide) return
      if (value) {
        safeLocalStorageSetItem(tierOverrideIdKey, value)
      } else {
        safeLocalStorageRemoveItem(tierOverrideIdKey)
      }
    },
    getTierOverrideId: async () => {
      if (isServerSide) return
      const tierOverrideId = safeLocalStorageGetItem(tierOverrideIdKey)
      return tierOverrideId ?? undefined
    },
  },
  brandConfig.clientName,
  (error) => {
    // Avoid logging network errors
    if (deepUnknownEqual(error, 'details.error.name', 'TypeError')) return

    Bugsnag.notify(error.message, (event) => {
      event.addMetadata('details', {
        ...error.details,
        serverTime: getServerTime(),
      })
    })
  }
)

authenticationStore.initialize()

if (isClientSide && window.authenticationTokens) {
  // Set default profile to avoid showing profile selector when opening the webview
  authenticationStore.changeProfile('default', false)
  authenticationStore.login(window.authenticationTokens.refreshToken, window.authenticationTokens.accessToken)
  window.authenticationTokens = undefined
}

export function useAuthenticationStore() {
  return useSyncExternalStore(
    authenticationStore.subscribe,
    authenticationStore.getSnapshot,
    // The logged out state will be returned on the server since we don't have a token there
    authenticationStore.getSnapshot
  )
}
