import type { ReactNode } from 'react'
import { createContext, useCallback, useContext, useEffect, useState } from 'react'
import { NotificationSnackbar } from './notification-snackbar'

export type NotificationSnackbarType = 'positive' | 'negative' | 'no-icon'

export const snackbarAnimationSpeed = 500

export enum NotificationSnackbarDuration {
  SHORT = 1600,
  MEDIUM = 3200,
  LONG = 4800,
  EXTRA_LONG = 10000,
  ONE_DAY = 86400000,
}

export type SnackbarMessage = {
  text: ReactNode
  type?: NotificationSnackbarType
  duration?: NotificationSnackbarDuration
}

type NotificationSnackbarContextProps = {
  showSnackbarMessage: (message: SnackbarMessage) => void
}

export const NotificationSnackbarContext = createContext<NotificationSnackbarContextProps | undefined>(undefined)

export const useNotificationSnackbar = () => {
  const context = useContext(NotificationSnackbarContext)
  if (context === undefined) {
    throw new Error('useNotificationSnackbar must be used within a SnackbarProvider')
  }
  return context
}

export const NotificationSnackbarProvider = ({ children }: { children: React.ReactNode }) => {
  const [message, setMessage] = useState<SnackbarMessage | undefined>(undefined)
  const [isSnackbarVisible, setIsSnackbarVisible] = useState(false)

  const showSnackbarMessage = useCallback((message: SnackbarMessage) => {
    setMessage(message)
    setIsSnackbarVisible(true)
  }, [])

  useEffect(() => {
    if (message) {
      const timer = setTimeout(
        () => {
          setIsSnackbarVisible(false)
        },
        (message.duration || NotificationSnackbarDuration.SHORT) + snackbarAnimationSpeed
      )

      return () => clearTimeout(timer)
    }
  }, [message])

  return (
    <NotificationSnackbarContext.Provider value={{ showSnackbarMessage }}>
      {children}

      {message && (
        <NotificationSnackbar isOpen={isSnackbarVisible} setIsOpen={setIsSnackbarVisible} message={message} />
      )}
    </NotificationSnackbarContext.Provider>
  )
}
