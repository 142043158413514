import packageJson from '@@/package.json'
import { brandConfig } from '@/config/brand'

export function getClientVersion() {
  return packageJson.version
}

export const clientNameAndVersionHeaders = {
  'client-name': brandConfig.clientName,
  'client-version': getClientVersion(),
}
