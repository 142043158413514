import { useCallback } from 'react'
import useWebSocket from 'react-use-websocket'
import type { Options as WebsocketOptions } from 'react-use-websocket'
import type { GetNotificationsResponse } from '@nordic-web/rest-codegen/generated/reno'

type RenoNotification = GetNotificationsResponse['notifications'][0]

type Options = {
  shouldConnect: boolean
  userAgent?: string
  getAccessToken: () => Promise<string | undefined>
  baseUrl: string
  clientName: string
  clientVersion: string
}

function formatRenoUrl(baseUrl: string, clientName: string, clientVersion: string) {
  return `${baseUrl}/ws/${clientName}/${clientVersion.replaceAll('.', '')}`
}

export const useReno = (options: Options) => {
  const { shouldConnect, getAccessToken, userAgent, baseUrl, clientName, clientVersion } = options

  const socketUrl = formatRenoUrl(baseUrl, clientName, clientVersion)

  const websocketOptions: WebsocketOptions = {
    headers: {
      'User-Agent': userAgent ?? '',
    },
    retryOnError: true,
    reconnectAttempts: Infinity,
    heartbeat: {
      interval: 60_000 * 5,
      timeout: 60_000 * 10,
    },
    filter: (message) => message.data !== 'pong',
    reconnectInterval: (lastAttemptNumber: number) => Math.min(1000 * 2 ** lastAttemptNumber, 60_000),
    shouldReconnect: (_closeEvent) => true,
    // To reuse WebSocket instances across hook instances
    share: true,
  }

  const getUrl = useCallback(async () => {
    const token = await getAccessToken()
    return socketUrl + `?token=${token}`
  }, [getAccessToken, socketUrl])

  const socket = useWebSocket<RenoNotification>(getUrl, websocketOptions, shouldConnect)

  return { socketNotification: socket.lastJsonMessage }
}
