import { useEffect, useState } from 'react'
import packageJson from '@@/package.json'
import { DebuggerPlugin } from '@snowplow/browser-plugin-debugger'
import type { TrackerConfiguration } from '@snowplow/browser-tracker'
import {
  clearUserData,
  newTracker,
  setUserId,
  trackPageView as snowplowPageView,
  trackSelfDescribingEvent,
} from '@snowplow/browser-tracker'
import { brandConfig } from '@/config/brand'
import { useAuthenticationStore } from '@/features/auth/authentication-store'
import { useUserQuery } from '@/features/user/hooks/use-user-query'
import { clientNameAndVersionHeaders } from '@/helpers/client-name-and-version-headers'
import { nextConfig } from '@/helpers/env'

const config: TrackerConfiguration = {
  appId: brandConfig.clientName,
  postPath: '/v2/events',
  credentials: 'omit',
  eventMethod: 'post',
  plugins: nextConfig.bool('NORDIC_SEARCH_EVENT_TRACKING_DEBUG') ? [DebuggerPlugin()] : [],
  encodeBase64: false,
  customHeaders: {
    ...clientNameAndVersionHeaders,
  },
}

const initTracker = () => {
  const endpoint = nextConfig.string('NORDIC_SEARCH_EVENT_TRACKING_ENDPOINT')
  return newTracker('nordic-web', endpoint, config)
}

export const trackPageView = () => {
  snowplowPageView()
}

export const track = (data: object, schema: string) => {
  trackSelfDescribingEvent({
    event: {
      schema,
      data: { app_version: packageJson.version, ...data },
    },
  })
}

type UseSnowplowOptions = {
  sendPageViewOnInit: boolean
}

export const SnowplowTrackInitiate = ({ sendPageViewOnInit }: UseSnowplowOptions) => {
  const [isReadyToTrack, setIsReadyToTrack] = useState(false)
  const { isLoggedIn } = useAuthenticationStore()
  const { user } = useUserQuery()
  const userId = user?.details.id

  useEffect(() => {
    initTracker()
  }, [])

  useEffect(() => {
    if (isLoggedIn) {
      if (userId) {
        setUserId(userId)
        setIsReadyToTrack(true)
      }
    } else {
      clearUserData()
      setIsReadyToTrack(true)
    }
  }, [isLoggedIn, userId])

  useEffect(() => {
    if (sendPageViewOnInit && isReadyToTrack) {
      trackPageView()
    }
  }, [sendPageViewOnInit, isReadyToTrack])

  return null
}
