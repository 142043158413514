import type { FromSchema } from 'json-schema-to-ts'
import { schemas } from './generated/schemas'

export const corePageSchema = schemas['tvmedia/core_page/jsonschema/1-0-0']
export const coreAssetImpressionSchema = schemas['tvmedia/core_asset_impression/jsonschema/1-0-0']
export const coreAssetClickSchema = schemas['tvmedia/core_asset_click/jsonschema/1-0-0']
export const corePanelImpressionSchema = schemas['tvmedia/core_panel_impression/jsonschema/1-0-0']
export const corePanelClickSchema = schemas['tvmedia/core_panel_click/jsonschema/1-0-0']

export type CorePageEvent = Omit<FromSchema<typeof corePageSchema>, 'app_version'>
export type CoreAssetImpressionEvent = Omit<FromSchema<typeof coreAssetImpressionSchema>, 'app_version'>
export type CoreAssetClickEvent = Omit<FromSchema<typeof coreAssetClickSchema>, 'app_version'>
export type CorePanelImpressionEvent = Omit<FromSchema<typeof corePanelImpressionSchema>, 'app_version'>
export type CorePanelClickEvent = Omit<FromSchema<typeof corePanelClickSchema>, 'app_version'>
