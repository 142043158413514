import React from 'react'
import { useTranslations } from 'next-intl'
import { useRouter } from 'next/router'
import { Button, ButtonGroup, Dialog, Stack, TypographyLink, TypographyText } from '@nordic-web/ui-components'
import { brandConfig } from '@/config/brand'
import { useFeatureToggle } from '@/context/feature-toggle-context'
import { useUpdateUserDetailsMutation } from '@/features/user/hooks/use-update-user-details-mutation'
import { useUserQuery } from '@/features/user/hooks/use-user-query'
import { paths } from '@/helpers/paths'

export const ProfilingConsentModal = () => {
  const isFeatureEnabled = useFeatureToggle({ name: 'IsUserConsentEnabled' })
  const t = useTranslations()
  const { updateUser, isLoading, error } = useUpdateUserDetailsMutation()
  const router = useRouter()
  const isStartpage = router.pathname === '/'
  const { user, isChildProfile } = useUserQuery()
  const shouldPrompt = !!user?.details.consents.thirdPartyAdvertisementConsent?.shouldPrompt
  const showDialog = isFeatureEnabled && isStartpage && !isChildProfile && shouldPrompt && !error

  function submit(accepted: boolean) {
    updateUser({
      variables: {
        input: {
          consents: {
            thirdPartyAdvertisementConsent: {
              accepted,
            },
          },
        },
      },
    })
  }

  return (
    <Dialog nwInitOpen={showDialog} nwVariant="stacked-small" nwCloseOnBackdropClick={false}>
      <Dialog.Body>
        <TypographyText nwVariant="title3" nwTextAlign="center">
          {t('third_party_profiling_consent__heading')}
        </TypographyText>
        <Stack nwGap={12}>
          <TypographyText nwVariant="body3" nwTextAlign="center" nwColor="secondary">
            {t.rich('third_party_profiling_consent__description', {
              link: (linkText) => (
                <TypographyLink
                  nwColor="secondary"
                  href={paths.article.urlString({ articleId: brandConfig.gdprLinks.privacyPolicy })}
                  target="_blank"
                >
                  {linkText}
                </TypographyLink>
              ),
            })}
          </TypographyText>
          <ButtonGroup nwFullWidth>
            <Button nwVariant="ghost" nwLayout="full-width" disabled={isLoading} onClick={() => submit(false)}>
              {t('third_party_profiling_consent__deny')}
            </Button>
            <Button nwVariant="primary" nwLayout="full-width" disabled={isLoading} onClick={() => submit(true)}>
              {t('third_party_profiling_consent__accept')}
            </Button>
          </ButtonGroup>
        </Stack>
      </Dialog.Body>
    </Dialog>
  )
}
