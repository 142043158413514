import { getTldFromHostname } from '@nordic-web/utils/authentication/get-tld-from-hostname'
import { parseJwt } from '@nordic-web/utils/authentication/token'
import { BRAND } from '@/config/brand'
import { getCookie, removeCookie, withDefaultCookieOptions } from '@/utils/cookies'

export const REFRESH_COOKIE_NAME = `${BRAND}-refresh-token`

/** get refresh token from cookie, used to refresh the access token. */
export const getRefreshTokenFromCookie = () => {
  return getCookie(null)[REFRESH_COOKIE_NAME]
}

/** remove refresh token from cookie, used to refresh the access token. */
export const removeRefreshTokenFromCookie = () => {
  removeCookie(null, REFRESH_COOKIE_NAME)
  // In march 2025 we changed the cookie to be on the top level domain
  removeCookie(
    null,
    REFRESH_COOKIE_NAME,
    withDefaultCookieOptions({
      domain: getTldFromHostname(window.location.hostname),
    })
  )
  // Remove with secure too just to be sure...
  removeCookie(null, REFRESH_COOKIE_NAME, withDefaultCookieOptions({ secure: false }))
}

/** random value between 1-120s to add to the refresh timer to avoid all users refreshing at the same time */
const randomFuzzyValue = Math.floor(Math.random() * 120) + 1

/** calculate the expire time in ms from access token */
export const getExpireTime = (accessToken?: string): number | undefined => {
  const payload = parseJwt(accessToken)
  if (!payload) return

  const { exp, iat } = payload
  const now = Math.floor(Date.now() / 1000)
  const calculateTimer = exp - (now + (exp - iat) / 2 + randomFuzzyValue)
  return Math.round(calculateTimer * 1000)
}
