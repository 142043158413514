import type { FromSchema } from 'json-schema-to-ts'
import { schemas } from './generated/schemas'

export const shortViewSchema = schemas['tvmedia/short_view/jsonschema/1-0-0']
export const shortSummarySchema = schemas['tvmedia/short_summary/jsonschema/1-0-0']
export const shortShareSchema = schemas['tvmedia/short_share/jsonschema/1-0-0']

export type ShortViewEvent = Omit<FromSchema<typeof shortViewSchema>, 'app_version'>
export type ShortSummaryEvent = Omit<FromSchema<typeof shortSummarySchema>, 'app_version'>
export type ShortShareEvent = Omit<FromSchema<typeof shortShareSchema>, 'app_version'>
