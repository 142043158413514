import { HttpLink, from, split } from '@apollo/client'
import { ApolloClient, ApolloLink, Observable } from '@apollo/client/core'
import type { NormalizedCacheObject } from '@apollo/client/core'
import { BatchHttpLink } from '@apollo/client/link/batch-http'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries'
import Bugsnag from '@bugsnag/js'
import { sha256 } from 'crypto-hash'
import { Kind } from 'graphql'
import { v4 as uuidv4 } from 'uuid'
import { createInMemoryCache } from '@nordic-web/gql/src/apollo-in-memory-cache'
import { formatAuthorizationHeader } from '@nordic-web/utils/authentication/format-authorization-header'
import { parseJwt } from '@nordic-web/utils/authentication/token'
import { getServerTime } from '@nordic-web/utils/date/get-server-time'
import { isClientSide } from '@nordic-web/utils/misc/detect-side'
import { isValidTierValue } from '@/components/tier-override-select'
import { authenticationStore } from '@/features/auth/authentication-store'
import { isNewsExperienceEnabled } from '@/features/shorts/is-news-experience-enabled'
import { clientNameAndVersionHeaders } from '@/helpers/client-name-and-version-headers'
import { nextConfig } from '@/helpers/env'
import { addBugsnagMetadata } from '@/lib/bugsnag/bugsnag'

let apolloClient: ApolloClient<NormalizedCacheObject> | null

// Used to keep track of the shorts algorithm session
export const sessionId = uuidv4()

const GRAPHQL_URL = nextConfig.string('GRAPHQL_URL')
// This is nice when testing to see what query is actually requested in dev tools
const shouldDisablePersistedQueries = nextConfig.bool('DISABLE_PERSISTED_QUERIES')

const tierOverrideLink = setContext((_, { headers }) => {
  if (isClientSide) {
    const tierOverride = authenticationStore.getSnapshot().tierOverrideId

    if (isValidTierValue(tierOverride)) {
      return {
        headers: {
          'tier-override-id': tierOverride,
          ...headers,
        },
      }
    }
  }

  return { headers }
})

const correlationIdLink = setContext((_, { headers }) => {
  return {
    headers: {
      'x-correlation-id': uuidv4(),
      ...headers,
    },
  }
})

// This link uses the observable approach to be able to cancel the request if the access token request fails.
// When that request fails, we dont want to continue the request and cause a 401 reply from graphql
const authLink = new ApolloLink((operation, forward) => {
  return new Observable((observer) => {
    authenticationStore
      .getValidAccessToken()
      .then((accessToken) => {
        const headers = operation.getContext().headers || {}

        if (accessToken) {
          const parsedJwt = parseJwt(accessToken)
          const entitlements = parsedJwt?.entitlements

          operation.setContext({
            headers: {
              ...headers,
              authorization: formatAuthorizationHeader(accessToken),
              ...(entitlements ? { 'user-entitlements': entitlements?.join(',') } : {}),
            },
          })
        } else {
          operation.setContext({ headers })
        }

        const subscriber = forward(operation).subscribe({
          next: (result) => observer.next(result),
          error: (error) => observer.error(error),
          complete: () => observer.complete(),
        })

        return () => {
          if (subscriber) subscriber.unsubscribe()
        }
      })
      .catch((error) => {
        observer.error(error)
      })
  })
})

function getTokenInfo() {
  const accessTokenInfo = parseJwt(authenticationStore.getSnapshot().accessToken)
  if (!accessTokenInfo) return
  const { exp, iat } = accessTokenInfo
  return {
    exp: new Date(exp * 1000).toUTCString(),
    iat: new Date(iat * 1000).toUTCString(),
    currentTime: new Date().toUTCString(),
    serverTime: getServerTime().toUTCString(),
  }
}

const errorLink = onError(({ networkError, graphQLErrors, operation }) => {
  if (networkError) {
    if ('statusCode' in networkError && networkError.statusCode !== undefined) {
      Bugsnag.notify(
        'A network error occured when calling the graphql API',
        addBugsnagMetadata({
          id: networkError.name,
          error_code: networkError.statusCode,
          error_message: networkError.message,
          rawError: networkError,
        })
      )
    }
  }

  const tokenInfo = getTokenInfo()

  // If the operation is a mutation, we dont want to send the variables to bugsnag since it can contain sensitive data
  const hasMutation = operation.query.definitions.some(
    (definition) => definition.kind === Kind.OPERATION_DEFINITION && definition.operation === 'mutation'
  )

  if (graphQLErrors) {
    graphQLErrors.forEach((error) => {
      // 403 and 400 are validation errors
      const status_code = error?.extensions?.status_code
      if (typeof status_code === 'string' && ['403', '400'].includes(status_code)) return

      Bugsnag.notify(
        'A graphql error was returned from the graphql API',
        addBugsnagMetadata({
          id: error.path?.join('.'),
          error_code: typeof status_code === 'string' ? status_code : undefined,
          error_message: error.message,
          rawError: error,
          tokenInfo,
          ...(!hasMutation && { variables: operation.variables }),
        })
      )
    })
  }
})

const featureFlagHeaders = {
  'feature-toggle-include-sportevents-in-endscreen-recommendations': 'true',
  'feature-toggle-include-episodes-in-endscreen-recommendations': 'true',
  'feature-toggle-include-multi-single-panel': 'true',
  'feature-toggle-news-experience': isNewsExperienceEnabled ? 'true' : 'false',
  'feature-toggle-include-sport-in-cw': 'true',
}

const commonHttpOptions = () => ({
  uri: GRAPHQL_URL + '/graphql',
  credentials: 'same-origin',
  headers: {
    ...clientNameAndVersionHeaders,
    'session-id': sessionId,
    ...featureFlagHeaders,
  },
  fetch,
})

const batchLink = () =>
  new BatchHttpLink({
    ...commonHttpOptions(),
    batchMax: 20, // a max number of items to batch, defaults at 10
  })

const persistedQueryhttpLink = () => {
  const httpLink = new HttpLink(commonHttpOptions())

  if (shouldDisablePersistedQueries) {
    return from([correlationIdLink, httpLink])
  }

  return from([correlationIdLink, createPersistedQueryLink({ useGETForHashedQueries: true, sha256 }), httpLink])
}

type SafeApolloClient = ApolloClient<NormalizedCacheObject> & {
  toJSON?: () => null
}

function create(stateFromServer?: NormalizedCacheObject) {
  const client: SafeApolloClient = new ApolloClient({
    connectToDevTools: isClientSide,
    ssrMode: !isClientSide, // Disables forceFetch on the server (so queries are only run once)
    link: from([
      errorLink,
      tierOverrideLink,
      authLink,
      split((operation) => operation.getContext().batch, batchLink(), persistedQueryhttpLink()),
    ]),
    cache: createInMemoryCache().restore(stateFromServer || {}),
    defaultOptions: {
      query: {
        // Setting this to "all" will make sure we render partial errors.
        // Default is "none", which means the data in the api response is discarded and not usable in the app
        // https://www.apollographql.com/docs/react/data/error-handling#graphql-error-policies
        errorPolicy: 'all',
      },
    },
  })

  // See this issue for more info: https://github.com/vercel/next.js/issues/9336#issuecomment-1092830219
  client.toJSON = () => null

  return client
}

export function initApollo(stateFromServer?: NormalizedCacheObject) {
  // Make sure to create a new client for every server-side request so that data
  // isn't shared between connections (which would be bad)
  if (!isClientSide) {
    return create(stateFromServer)
  }
  // Reuse client on the client-side
  if (!apolloClient) {
    apolloClient = create(stateFromServer)
  }

  return apolloClient
}
