import { PageMetaTags } from '@/features/page/page-meta-tags'
import { PagePanelsFetcher } from '@/features/page/page-panels-fetcher'
import { NordicApp } from '@/layouts/app'
import type { Page } from '@/pages/types'

export const NEWSPAGE_ID = 'news'

const NewsPage: Page = () => {
  return (
    <>
      <PagePanelsFetcher id={NEWSPAGE_ID} showBreadcrumbs={false} />
      <PageMetaTags pageId={NEWSPAGE_ID} />
    </>
  )
}

NewsPage.getLayout = (Page, props) => {
  return (
    <NordicApp shouldOverrideHeaderMarginBehaviour>
      <Page {...props} />
    </NordicApp>
  )
}

export default NewsPage
