/* Live labels for a video (episode/movie) with a lot of tailored logic for showing different time formats depending on how close to live it is */
import React from 'react'
import { Label, type LabelPropsOptionals } from '@nordic-web/ui-components'
import { isCurrentlyLive } from '@nordic-web/utils/date'
import { getServerTime } from '@nordic-web/utils/date/get-server-time'
import { useReRenderWithInterval } from '@nordic-web/utils/hooks/use-re-render-interval'

type VideoLiveLabelsProps = {
  playableFrom: {
    readableDateTime: string
    isoString: string
  }
  liveEventEnd?: {
    isoString: string
  } | null
  labelProps?: LabelPropsOptionals
  className?: string
}

export const VideoLiveLabels = ({ playableFrom, liveEventEnd, labelProps = {}, ...rest }: VideoLiveLabelsProps) => {
  useReRenderWithInterval(1_000)

  const broadcastDateTime = new Date(playableFrom.isoString)

  if (hasBroadcastFinished(liveEventEnd)) return null

  if (isCurrentlyLive({ eventStartTime: broadcastDateTime })) {
    return <Label nwVariant="live" {...labelProps} {...rest} />
  }

  return (
    <Label nwVariant="airtime" {...labelProps} {...rest}>
      {playableFrom?.readableDateTime}
    </Label>
  )
}

export const hasBroadcastFinished = (liveEventEnd?: VideoLiveLabelsProps['liveEventEnd']) => {
  if (!liveEventEnd) return false

  const now = getServerTime()
  return new Date(liveEventEnd.isoString).getTime() < now.getTime()
}
