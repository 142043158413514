/* eslint-disable import/no-unresolved */
import type { ElementType } from 'react'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslations } from 'next-intl'
import type { SwiperOptions, Swiper as SwiperType } from 'swiper'
import { A11y, FreeMode, Mousewheel, Virtual } from 'swiper'
import 'swiper/css'
import 'swiper/css/virtual'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Icon } from '@nordic-web/ui-components'
import { defaultBreakpoints } from '@/components/slider/slider-breakpoints'
import { detectTouch } from '@/utils/detect-touch'
import { Container, NavigationButton, SPACE_BETWEEN } from './styles'

type Direction = 'prev' | 'next'

type SliderArrowProps = {
  direction: Direction
  disabled: boolean
  onClick: (direction: Direction) => void
}

const SliderArrow = ({ direction, disabled, onClick }: SliderArrowProps) => {
  const t = useTranslations()
  return (
    <NavigationButton
      aria-hidden={disabled}
      aria-label={`${direction === 'prev' ? t('panel__show_earlier') : t('panel__show_more')}`}
      className={`slider-button-${direction} ${disabled ? 'slider-button-disabled' : ''}`}
      disabled={disabled}
      onClick={() => {
        onClick(direction)
      }}
    >
      <Icon nwVariant="chevron-down" nwSize="medium" />
    </NavigationButton>
  )
}

export type OnSliderLoadMore = (index: number) => void

type SliderProps = {
  children: React.ReactNode[]
  onLoadMore?: OnSliderLoadMore
  fetchMoreData?: () => void
  hasMoreItems?: boolean
  breakpoints?: SwiperOptions['breakpoints']
  onIndexChange?: (index: number) => void
  sliderItemTag?: ElementType
  activeIndex?: number
}

export const Slider = ({
  children,
  onLoadMore,
  hasMoreItems,
  breakpoints: _breakpoints = {},
  fetchMoreData,
  onIndexChange,
  sliderItemTag = 'div',
  activeIndex,
}: SliderProps) => {
  const swiperRef = useRef<SwiperType | null>(null)
  const [shouldDisablePrevButton, setShouldDisablePrevButton] = useState(true)
  const [shouldDisableNextButton, setDisableNextButton] = useState(true)
  const dataFetchedIndex = useRef<number>(0)
  const breakpoints = useMemo(() => ({ ...defaultBreakpoints, ..._breakpoints }), [_breakpoints])
  const isTouchDevice = detectTouch()

  const handleNavigation = (direction: Direction) => {
    if (!swiperRef.current) {
      return null
    }

    if (direction === 'next') {
      swiperRef.current.slideNext()
      setShouldDisablePrevButton(false)
    } else {
      swiperRef.current.slidePrev()
      setDisableNextButton(false)
    }
  }

  useEffect(() => {
    if (swiperRef.current?.allowSlideNext) {
      setDisableNextButton(false)
    }

    if (activeIndex) {
      swiperRef.current?.slideTo(activeIndex, 0)
    }
  }, [activeIndex])

  const shouldFetchMore = (index: number, swiper: SwiperType) => {
    if (!hasMoreItems) return

    const slidesPerGroup = swiper.params.slidesPerGroup ?? 0
    const lastIndexInView = index + slidesPerGroup
    const numberOfLoadedItems = children.length
    const numberOfBufferedItems = Math.max(slidesPerGroup, 3)

    // Let's make sure that there always is at least one "page" out of view to the right
    if (numberOfLoadedItems <= lastIndexInView + numberOfBufferedItems) {
      dataFetchedIndex.current = index
      fetchMoreData?.()
    }
  }

  return (
    <Container>
      <Swiper
        breakpoints={breakpoints}
        longSwipesRatio={0.2}
        modules={[A11y, Virtual, FreeMode, Mousewheel]}
        onFromEdge={() => {
          setDisableNextButton(false)
          setShouldDisablePrevButton(false)
        }}
        onReachBeginning={() => {
          setShouldDisablePrevButton(true)
        }}
        onReachEnd={() => {
          setDisableNextButton(!hasMoreItems)
        }}
        onSlideNextTransitionEnd={(swiper) => {
          onLoadMore?.(swiper.activeIndex)
          shouldFetchMore(swiper.activeIndex, swiper)
        }}
        onSwiper={(swiper) => {
          swiperRef.current = swiper
        }}
        spaceBetween={SPACE_BETWEEN}
        threshold={10}
        touchAngle={60}
        touchEventsTarget="container" // Needed to avoid iOS focus problems when swiping between cards
        virtual={{
          addSlidesAfter: 50, // Ideally these values change depending on breakpoints
          addSlidesBefore: 50,
        }}
        onSnapIndexChange={(swiper) => onIndexChange?.(swiper.snapIndex)}
        mousewheel={
          isTouchDevice
            ? false
            : {
                releaseOnEdges: true,
                forceToAxis: true,
              }
        }
        freeMode={!isTouchDevice}
        onScroll={(swiper) => {
          if (dataFetchedIndex.current === swiper.activeIndex) return

          const slidesPerGroup = swiper.params.slidesPerGroup ?? 3
          // Only fetch if you have pass the amount of group cards and are scrolling right
          if (swiper.activeIndex % slidesPerGroup === 0 && dataFetchedIndex.current < swiper.activeIndex) {
            shouldFetchMore(swiper.activeIndex, swiper)
          }
        }}
      >
        {children.map((child, index) => {
          return (
            <SwiperSlide tag={sliderItemTag as string} key={index} virtualIndex={index}>
              {child}
            </SwiperSlide>
          )
        })}
      </Swiper>
      <SliderArrow direction="prev" disabled={shouldDisablePrevButton} onClick={handleNavigation} />
      <SliderArrow direction="next" disabled={shouldDisableNextButton} onClick={handleNavigation} />
    </Container>
  )
}
