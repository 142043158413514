import { useRouter } from 'next/router'
import { usePanelQuery } from '@nordic-web/gql'
import { nonNullable } from '@nordic-web/utils/typescript'
import { ButtonLinkBar } from '@/components/button-link-bar'
import { paths } from '@/helpers/paths'
import { stringFromQueryParam } from '@/utils/query-string'

type CompactPagePanelProps = {
  panelId: string
}
export const CompactPagePanel = ({ panelId }: CompactPagePanelProps) => {
  const router = useRouter()

  const currentPage = stringFromQueryParam(router.query.id)

  const { data, loading: isLoading } = usePanelQuery({
    ssr: false,
    variables: {
      panelId,
      offset: 0,
      // A bit ungly to hard code 50, but i think in reality we wont have more than 10-ish items
      // so its a bit of a waste to create pagination logic
      limit: 50,
    },
  })

  if (isLoading || data?.panel?.__typename !== 'PagePanel') return null

  const items = data.panel.content.cards.filter(nonNullable).map((item) => {
    const { id } = item.content
    return {
      href: paths.page.urlString({ id }),
      label: item.linkText,
      selected: currentPage === id,
    }
  })

  return <ButtonLinkBar replace items={items} />
}
