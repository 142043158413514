import { useApolloClient } from '@apollo/client'
import { useTranslations } from 'next-intl'
import router from 'next/router'
import { useUserLazyQuery } from '@nordic-web/gql'
import { NotificationSnackbarDuration, useNotificationSnackbar } from '@nordic-web/ui-components'
import { useServiceMessages } from '@/components/service-messages/use-service-messages'
import { authenticationStore, logout } from '@/features/auth/authentication-store'
import { paths } from '@/helpers/paths'

export function useHandleRenoNotification() {
  const t = useTranslations()
  const apolloClient = useApolloClient()
  const [getUser] = useUserLazyQuery()
  const { showSnackbarMessage } = useNotificationSnackbar()

  const { refetch: refetchServiceMessages } = useServiceMessages()

  async function handleProfileChange(): Promise<void> {
    const userData = await getUser({ fetchPolicy: 'network-only', ssr: false })
    if (!userData?.data?.user?.activeProfile?.id && !paths.selectProfile.isActive(router)) {
      await router.push(paths.selectProfile.urlString({ return: router.asPath }))
      showSnackbarMessage({
        text: t('profiles__error_profile_deleted'),
        duration: NotificationSnackbarDuration.LONG,
        type: 'negative',
      })
    }
  }

  function handleLogoutAll(): void {
    logout()
  }

  function handleServiceMessagesUpdated(): void {
    refetchServiceMessages()
  }

  async function handleSubscriptionChange(): Promise<void> {
    await authenticationStore.refreshAccessToken()
    apolloClient.resetStore()
  }

  return { handleProfileChange, handleLogoutAll, handleServiceMessagesUpdated, handleSubscriptionChange }
}
