/**
 * Safely checks if a nested property path of an object equals a value
 * @param obj The object to check
 * @param path Dot notation path to the property (e.g. 'details.error.name')
 * @param value The value to compare against
 * @returns boolean
 */
export function deepUnknownEqual(obj: unknown, path: string, value: unknown): boolean {
  try {
    const properties = path.split('.')
    let current: LegitimateAny = obj

    for (const prop of properties) {
      if (current === null || current === undefined) {
        return false
      }
      current = current[prop]
    }

    return current === value
  } catch {
    return false
  }
}
