import React, { useState } from 'react'
import type { LivePanelFieldsFragment } from '@nordic-web/gql'
import { useLivePanelQuery } from '@nordic-web/gql'
import { nonNullable } from '@nordic-web/utils/typescript'
import { LiveCard } from '@/components/cards/live-card/live-card'
import { Slider } from '@/components/slider/slider'
import { liveCardBreakpoints } from '@/components/slider/slider-breakpoints'
import { getSwipeModuleApiLimit } from '@/components/swipe-module/swipe-module'
import { PanelTitle } from '@/components/typography/panel-title'

export const LivePanel = ({ panel, index }: { panel: LivePanelFieldsFragment; index: number }) => {
  const { data, fetchMore } = useLivePanelQuery({
    fetchPolicy: 'cache-and-network',
    ssr: false,
    variables: {
      panelId: panel.id,
      offset: 0,
      limit: getSwipeModuleApiLimit(),
    },
  })

  const [activeIndex, setActiveIndex] = useState(0)

  if (data?.panel?.__typename !== 'LivePanel') return null

  const items = data.panel.content.cards.filter(nonNullable).map((item) => item.content)
  const shouldPlayVideoOutOfView = data.panel.shouldPlayVideoOutOfView
  const shouldPlayVideo = data.panel.shouldPlayVideo
  const pageInfo = data.panel.content.pageInfo
  const hasNextPage = !!pageInfo?.hasNextPage

  return (
    <>
      <PanelTitle firstTitle={index === 0}>{panel.title}</PanelTitle>
      <Slider
        breakpoints={liveCardBreakpoints}
        hasMoreItems={hasNextPage}
        onIndexChange={(index) => setActiveIndex(index)}
        fetchMoreData={() => {
          fetchMore({
            variables: {
              limit: getSwipeModuleApiLimit(),
              offset: pageInfo?.nextPageOffset,
            },
          })
        }}
      >
        {items.map((item, index) => (
          <LiveCard
            key={item.id}
            asset={item}
            isActive={index === activeIndex}
            shouldPlayVideoOutOfView={shouldPlayVideoOutOfView}
            shouldDisableVideo={!shouldPlayVideo}
          />
        ))}
      </Slider>
    </>
  )
}
