import { useCallback, useEffect, useState } from 'react'
import { useReno } from '@nordic-web/reno/hooks/use-reno'
import { brandConfig } from '@/config/brand'
import { authenticationStore, useAuthenticationStore } from '@/features/auth/authentication-store'
import type { RenoNotification } from '@/features/reno/types'
import { getClientVersion } from '@/helpers/client-name-and-version-headers'
import { nextConfig } from '@/helpers/env'
import { useHandleRenoNotification } from './use-handle-reno-notification'

export const RenoNotificationListener = () => {
  const { isLoggedIn } = useAuthenticationStore()
  const [publishedNotifications, setPublishedNotifications] = useState<string[]>([])
  const renoHandlers = useHandleRenoNotification()

  const getAccessToken = useCallback(() => authenticationStore.getValidAccessToken(), [])

  const { socketNotification } = useReno({
    getAccessToken,
    shouldConnect: isLoggedIn,
    baseUrl: nextConfig.string('RENO_API'),
    clientName: brandConfig.clientName,
    clientVersion: getClientVersion(),
  })

  const handleNotification = useCallback(
    (notification: RenoNotification) => {
      const isPublished = publishedNotifications.includes(notification?.id)
      if (isPublished) return

      switch (notification.type) {
        case 'LOGOUT_ALL':
          renoHandlers.handleLogoutAll()
          break
        case 'PROFILES_CHANGED':
          renoHandlers.handleProfileChange()
          break
        case 'SUBSCRIPTION_CHANGED':
          renoHandlers.handleSubscriptionChange()
          break
        case 'SERVICE_MESSAGES_UPDATED':
          renoHandlers.handleServiceMessagesUpdated()
          break
        default:
          console.error('Unhandled reno notification type', notification.type)
          break
      }

      setPublishedNotifications([...publishedNotifications, notification.id])
    },
    [publishedNotifications, renoHandlers]
  )

  useEffect(() => {
    if (socketNotification) {
      handleNotification(socketNotification)
    }
  }, [socketNotification, handleNotification])

  return null
}
