import { useState } from 'react'
import styled from 'styled-components'
import { Autoplay } from 'swiper'
import 'swiper/css'
import { SwiperSlide, useSwiper, useSwiperSlide } from 'swiper/react'
import type { SinglePanelFieldsFragment } from '@nordic-web/gql'
import { NavigationDots, Stack } from '@nordic-web/ui-components'
import { FullScreenSwiper } from '@/components/full-screen-swiper'
import { HeroRatioContainer } from '@/components/hero/hero'
import { NavigationArrow } from '@/components/navigation-arrow'
import { SinglePanel } from '@/features/panel-factory/components/single-panel'
import { useSwiperActiveIndex, useSwiperSlideCount } from '@/features/video/rich-player/endscreen.hooks'

type MultiSinglePanel = {
  panels: SinglePanelFieldsFragment[]
}

type SlideProps = {
  panel: SinglePanelFieldsFragment
  index: number
}

type SwiperNavigationNavigationProps = {
  showNavigationArrows: boolean
}

function SwiperNavigationNavigation({ showNavigationArrows }: SwiperNavigationNavigationProps) {
  const swiper = useSwiper()
  const activeIndex = useSwiperActiveIndex()
  const count = useSwiperSlideCount()

  return (
    <>
      <NavigationArrow direction="left" show={showNavigationArrows} onClick={() => swiper.slidePrev()} />
      <Stack slot="container-end" nwAlignItems="center">
        <NavigationDots activeIndex={activeIndex} count={count} onDotClick={(index) => swiper.slideToLoop(index)} />
      </Stack>
      <NavigationArrow direction="right" show={showNavigationArrows} onClick={() => swiper.slideNext()} />
    </>
  )
}

function Slide({ panel, index }: SlideProps) {
  const swiper = useSwiper()
  const { isActive } = useSwiperSlide()

  function onTrailerPlay() {
    swiper.autoplay.stop()
  }

  function onTrailerEnd() {
    setTimeout(() => {
      if (isActive) {
        swiper.slideNext()
        swiper.autoplay.start()
      }
    }, 4000)
  }

  return (
    <SinglePanel
      index={index}
      panel={panel}
      onTrailerEnd={onTrailerEnd}
      onTrailerPlay={onTrailerPlay}
      isActive={isActive}
    />
  )
}

export function MultiSinglePanel({ panels }: MultiSinglePanel) {
  const [showNavigationArrows, setShowNavigationArrows] = useState(false)

  return (
    // This ratio container is needed to let the div have a size before the swiper is initialized, without this the
    // scrollX position isnt set correctly when navigating back in the browser.
    <HeroRatioContainer
      onMouseEnter={() => setShowNavigationArrows(true)}
      onMouseLeave={() => setShowNavigationArrows(false)}
    >
      <MultiSwiper loop autoplay={{ delay: 6000 }} modules={[Autoplay]}>
        {panels.map((panel, index) => (
          <SwiperSlide key={panel.id}>
            <Slide panel={panel} index={index} />
          </SwiperSlide>
        ))}
        <SwiperNavigationNavigation showNavigationArrows={showNavigationArrows} />
      </MultiSwiper>
    </HeroRatioContainer>
  )
}

const MultiSwiper = styled(FullScreenSwiper)({
  overflow: 'visible',
  position: 'relative',
  zIndex: 0,
})
