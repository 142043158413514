import styled from 'styled-components'

export const FormWrapperBase = styled.div<{ disabled?: boolean }>(({ disabled }) => ({
  opacity: disabled ? 0.2 : 1,
  display: 'grid',
  gridTemplateColumns: '1fr max-content',
  gridTemplateRows: '1fr max-content',
  width: '100%',
  FormValidationMessage: {
    gridRow: 2,
    gridColumn: '1 / -1',
  },
}))
