import React, { useMemo } from 'react'
import type { Ratio } from '@nordic-web/utils/typescript/common-types'
import { ClipCard } from '@/components/cards/clip-card'
import { EpisodeCard } from '@/components/cards/episode-card'
import { LazyShortsCard } from '@/components/cards/lazy-shorts-card'
import { LogoPageCard } from '@/components/cards/page-card/logo-page-card'
import { PageCard } from '@/components/cards/page-card/page-card'
import { PosterCard } from '@/components/cards/poster-card'
import { ProgramCard } from '@/components/cards/program-card'
import { SportEventCard } from '@/components/cards/sport-event-card/sport-event-card'
import type { OnCardClick } from '@/components/cards/types'
import { ChannelCard } from '@/components/channel-card'
import type { SwipeModuleItem } from '@/components/swipe-module/swipe-module'
import { paths } from '@/helpers/paths'

type CardFactoryProps = {
  ratio?: Ratio
  cardData: SwipeModuleItem
  onCardClick?: OnCardClick
  index?: number
  showOnlyUpsellLabel?: boolean
  showClipCardForShorts?: boolean
  panelId?: string
}

export const CardFactory = ({
  cardData,
  onCardClick,
  ratio,
  index,
  showOnlyUpsellLabel,
  showClipCardForShorts,
  panelId,
}: CardFactoryProps) => {
  const content = useMemo(() => {
    const handleCardClick = () => {
      if (onCardClick && cardData.__typename !== 'EpisodesPanelItem') {
        onCardClick(cardData, index)
      }
    }
    switch (cardData.__typename) {
      case 'Clip': {
        return <ClipCard onCardClick={handleCardClick} videoAsset={cardData} panelId={panelId} />
      }
      case 'EpisodesPanelItem': {
        return <EpisodeCard videoAsset={cardData.content} labelText={cardData.labelText} />
      }
      case 'Movie':
      case 'Series': {
        if (ratio === '2 / 3') {
          return (
            <PosterCard program={cardData} onCardClick={handleCardClick} showOnlyUpsellLabel={showOnlyUpsellLabel} />
          )
        }
        return (
          <ProgramCard onCardClick={handleCardClick} program={cardData} showOnlyUpsellLabel={showOnlyUpsellLabel} />
        )
      }
      case 'Channel': {
        return <ChannelCard channel={cardData} onCardClick={handleCardClick} />
      }
      case 'PageReference': {
        if (ratio === '16 / 9') {
          return (
            <PageCard
              onCardClick={handleCardClick}
              key={cardData.id}
              id={cardData.id}
              href={paths.page.urlString({ id: cardData.id })}
              imageSource={cardData.images.image16x9?.sourceEncoded ?? ''}
              gradientColor={cardData.images.image16x9?.meta?.muteBgColor?.hex}
              title={cardData.title}
            />
          )
        }

        return <LogoPageCard page={cardData} onCardClick={handleCardClick} />
      }
      case 'SportEvent': {
        return <SportEventCard content={cardData} onCardClick={handleCardClick} />
      }
      case 'Short':
        if (showClipCardForShorts) {
          return <ClipCard onCardClick={handleCardClick} videoAsset={cardData} />
        }

        return <LazyShortsCard short={cardData} onCardClick={handleCardClick} index={index} panelId={panelId} />
      case 'Episode': {
        // is replaced with EpisodesPanelItem
        return null
      }
    }
  }, [cardData, index, onCardClick, ratio, showOnlyUpsellLabel, showClipCardForShorts, panelId])

  return content
}
