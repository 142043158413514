import { useRef } from 'react'
import Link from 'next/link'
import { Filter, Stack, UtilHorizontalScrollArea } from '@nordic-web/ui-components'

type ButtonLinkBarProps = {
  items: Item[]
  replace?: boolean
}

type Item = {
  selected: boolean
  href?: string
  label: string
  onClick?: () => void
}

export const ButtonLinkBar = ({ items, replace = false }: ButtonLinkBarProps) => {
  const ref = useRef<HTMLButtonElement | null>(null)

  return (
    <UtilHorizontalScrollArea nwFullWidth nwScrollStartRef={ref}>
      <Stack nwGap={2} nwDirection="row">
        {items.map(({ href, label, selected, onClick }) => {
          const filter = (
            <Filter
              key={label}
              onClick={onClick}
              ref={selected ? ref : undefined}
              draggable="false"
              as={href ? 'a' : 'button'}
              nwSelected={selected}
            >
              {label}
            </Filter>
          )
          if (href) {
            return (
              <Link passHref legacyBehavior key={href} href={href} scroll={false} replace={replace}>
                {filter}
              </Link>
            )
          }
          return filter
        })}
      </Stack>
    </UtilHorizontalScrollArea>
  )
}
