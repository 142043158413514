import type { FromSchema } from 'json-schema-to-ts'
import { schemas } from './generated/schemas'

export const searchListResultClickSchema = schemas['tvmedia/search_list_result_click/jsonschema/3-1-0']
export const searchGridResultClickSchema = schemas['tvmedia/search_grid_result_click/jsonschema/3-1-0']
export const searchAbandonmentSchema = schemas['tvmedia/search_abandonment/jsonschema/3-1-0']
export const searchNoInputClickSchema = schemas['tvmedia/search_no_input_click/jsonschema/3-1-0']
export const searchResultItemImpressionSchema = schemas['tvmedia/search_result_item_impression/jsonschema/3-1-0']

export type SearchListResultClickEvent = Omit<FromSchema<typeof searchListResultClickSchema>, 'app_version'>
export type SearchGridResultClickEvent = Omit<FromSchema<typeof searchGridResultClickSchema>, 'app_version'>
export type SearchAbandonmentEvent = Omit<FromSchema<typeof searchAbandonmentSchema>, 'app_version'>
export type SearchNoInputClickEvent = Omit<FromSchema<typeof searchNoInputClickSchema>, 'app_version'>
export type SearchResultItemImpression = Omit<FromSchema<typeof searchResultItemImpressionSchema>, 'app_version'>
