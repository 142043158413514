export function getTldFromHostname(hostname: string): string {
  if (!hostname || hostname === 'localhost' || hostname.startsWith('localhost:')) {
    return ''
  }

  const parts = hostname.split('.')
  if (parts.length < 2) return ''

  return `.${parts.slice(-2).join('.')}`
}
