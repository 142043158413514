import styled from 'styled-components'
import { TypographyText } from '@nordic-web/ui-components'

export const TextOptionalStrikeThrough = styled(TypographyText)<{ strikeThrough?: boolean }>(
  ({ strikeThrough, theme }) => ({
    ...(strikeThrough ? { textDecoration: `${theme.color.surface.white50} line-through` } : {}),
  })
)

type PriceTextsProps = {
  isLarge: boolean
  isStrikeThrough: boolean
  nonHightlightedPrice: string
  highlightedPrice?: string
}

export const PriceTexts = ({ highlightedPrice, isLarge, nonHightlightedPrice, isStrikeThrough }: PriceTextsProps) => {
  return (
    <>
      <TypographyText nwColor="highlight" nwVariant={isLarge ? 'title2' : 'body1Strong'}>
        {highlightedPrice}
      </TypographyText>
      <TextOptionalStrikeThrough
        nwColor="tertiary"
        nwVariant={isLarge ? 'body1' : 'body4'}
        strikeThrough={isStrikeThrough}
      >
        {nonHightlightedPrice}
      </TextOptionalStrikeThrough>
    </>
  )
}
