import type { FC } from 'react'
import React from 'react'
import { useRouter } from 'next/router'
import { CardPlayingOverlay } from '@/components/cards/card-playing-overlay'
import { pathsPlayingVideos } from '@/helpers/paths'

type IsPlaying = {
  assetId: string
}

export const IsPlaying: FC<IsPlaying> = ({ assetId }) => {
  const router = useRouter()

  const isPlaying =
    router &&
    pathsPlayingVideos.some((path) => {
      // We know that there is a router.query.assetId, since the pathsPlayingVideos interface has the assetId property.
      // And params is used on the catch all route on the clips page.
      const routerAssetId = router.query.assetId || router.query.params?.[0]
      return path.isActive(router) && routerAssetId === assetId
    })

  if (!isPlaying) return null

  return <CardPlayingOverlay />
}
