import { useMediaProgressQuery } from '@nordic-web/gql'
import { isTypeInUnion } from '@nordic-web/utils/typescript'
import { useAuthenticationStore } from '@/features/auth/authentication-store'

export const useProgressQuery = (mediaId: string, skip = false) => {
  const { isLoggedIn } = useAuthenticationStore()

  const { data, loading: isLoading } = useMediaProgressQuery({
    fetchPolicy: 'network-only',
    variables: {
      mediaId,
    },
    skip: !isLoggedIn || skip,
    ssr: false,
    context: { batch: true },
  })

  const progress = isTypeInUnion(data?.media, 'Episode', 'Movie', 'SportEvent') ? data?.media.progress : null

  return {
    progress,
    isLoading,
  }
}
