import { useTranslations } from 'next-intl'
import Link from 'next/link'
import { Button } from '@nordic-web/ui-components'
import { isPast } from '@nordic-web/utils/date'
import type { StartoverVideoInfo } from '@/features/panel-factory/hooks/single-module-link-parser'
import { paths } from '@/helpers/paths'
import { useProgressQuery } from '@/hooks/progress/use-progress-query'
import { useIsBelowTablet } from '@/hooks/use-breakpoint'

type Props = {
  video: StartoverVideoInfo
  showWhenUserHasProgress?: boolean
}

export const RestartButton = ({ video, showWhenUserHasProgress = false }: Props) => {
  const t = useTranslations()
  const isMobile = useIsBelowTablet()

  const endTime = video.liveEventEnd?.isoString
  const hasEnded = endTime ? isPast(endTime) : false
  const startTime = video.playableFrom?.isoString
  const hasStarted = startTime ? isPast(startTime) : false
  const isStartoverEnabled = Boolean(hasStarted && !hasEnded && video.isStartOverEnabled && video.isLiveContent)
  const shouldFetchUserProgress = showWhenUserHasProgress && !isStartoverEnabled
  const { progress } = useProgressQuery(video.id, !shouldFetchUserProgress)
  const percent = progress?.percent

  if (percent || isStartoverEnabled) {
    return (
      <Link href={paths.video.urlString({ assetId: video.id, slug: video.slug, time: '0' })} legacyBehavior passHref>
        <Button as="a" nwVariant="secondary" nwIconFirst="skip-back" aria-label={t('general__play_from_beginning')}>
          {isMobile ? null : t('general__play_from_beginning')}
        </Button>
      </Link>
    )
  }

  return null
}
