import { useQuery } from '@tanstack/react-query'
import { servicemessagesApiApplicationPublishedMessagesHandler } from '@nordic-web/rest-codegen/generated/service-messages'

export function useServiceMessages() {
  return useQuery({
    queryKey: ['serviceMessages'],
    queryFn: async () => {
      const response = await servicemessagesApiApplicationPublishedMessagesHandler({
        throwOnError: true,
      })
      return response.data
    },
    refetchInterval: 1000 * 60 * 3, // milliseconds
    refetchOnWindowFocus: true,
  })
}
