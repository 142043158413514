import { blackColors, colorPrimitives, whiteColors } from './color.primitives'

export const color = {
  base: {
    primary: colorPrimitives.red1,
    primaryLight: colorPrimitives.pink3,
    primaryDark: colorPrimitives.red3,
    background: colorPrimitives.grey6,
    backgroundLight: colorPrimitives.grey5,
    white: colorPrimitives.white100,
    black: colorPrimitives.black100,
  },
  text: {
    primary: colorPrimitives.white100,
    primaryInverted: colorPrimitives.black100,
    secondary: colorPrimitives.white70,
    secondaryInverted: colorPrimitives.black70,
    tertiary: colorPrimitives.white50,
    tertiaryInverted: colorPrimitives.black50,
    disabled: colorPrimitives.white20,
    disabledInverted: colorPrimitives.black20,
    highlight: colorPrimitives.pink3,
    error: colorPrimitives.error,
    attention: colorPrimitives.attention,
    success: colorPrimitives.success,
    button: colorPrimitives.white100,
  },
  surface: {
    ...whiteColors,
    ...blackColors,
    cardDefault: colorPrimitives.white10,
    cardHoverFocused: colorPrimitives.white20,
    cardSolidHover: colorPrimitives.grey3,
    cardSolid: colorPrimitives.grey5,
    plus: colorPrimitives.black100,
  },
  icon: {
    primary: colorPrimitives.white100,
    primaryInverted: colorPrimitives.black100,
    secondary: colorPrimitives.white70,
    secondaryInverted: colorPrimitives.black70,
    tertiary: colorPrimitives.white50,
    tertiaryInverted: colorPrimitives.black50,
    disabled: colorPrimitives.white20,
    disabledInverted: colorPrimitives.black20,
    active: colorPrimitives.pink3,
    error: colorPrimitives.error,
    attention: colorPrimitives.attention,
    success: colorPrimitives.success,
  },
  border: {
    100: colorPrimitives.white100,
    50: colorPrimitives.white50,
    25: colorPrimitives.white25,
    active: colorPrimitives.pink3,
    disabled: colorPrimitives.white20,
    error: colorPrimitives.error,
  },
  alert: {
    information: colorPrimitives.black70,
    message: colorPrimitives.black100,
    messageInverted: colorPrimitives.white100,
    critical: colorPrimitives.error,
    alert: colorPrimitives.attention,
  },
  div: {
    inputField: colorPrimitives.white10,
    inputFieldInverted: colorPrimitives.black10,
    overlayBackground: colorPrimitives.black70,
    captionNewsClip: colorPrimitives.red1,
    captionClip: colorPrimitives.white100,
    dropdown: colorPrimitives.grey4,
  },
  filter: {
    default: colorPrimitives.white10,
    activeHover: colorPrimitives.white20,
    pressed: colorPrimitives.white5,
    selected: colorPrimitives.white50,
    hover: colorPrimitives.white20,
  },
  labels: {
    default: colorPrimitives.red1,
    reoccurring: colorPrimitives.pink3,
    airtime: colorPrimitives.white90,
    remaining: colorPrimitives.attention,
    rating: colorPrimitives.white20,
    live: colorPrimitives.red1,
    plus: colorPrimitives.black100,
  },
  progressBar: {
    background: colorPrimitives.white20,
    backgroundInverted: colorPrimitives.black20,
    default: colorPrimitives.red1,
    light: colorPrimitives.pink3,
  },
  status: {
    information: colorPrimitives.information,
    success: colorPrimitives.success,
    attention: colorPrimitives.attention,
    error: colorPrimitives.error,
  },
  tier: colorPrimitives.grey5,
  button: {
    primary: {
      default: colorPrimitives.red1,
      hover: colorPrimitives.red2,
      pressed: colorPrimitives.red3,
      disabled: colorPrimitives.white10,
    },
    secondary: {
      default: colorPrimitives.white20,
      hover: colorPrimitives.white40,
      pressed: colorPrimitives.white10,
      disabled: colorPrimitives.white10,
    },
    ghost: {
      default: 'transparent',
      hover: colorPrimitives.white20,
      pressed: colorPrimitives.white20,
      disabled: 'transparent',
    },
    subtle: {
      default: colorPrimitives.white10,
      hover: colorPrimitives.white15,
      pressed: colorPrimitives.white5,
      disabled: colorPrimitives.white10,
    },
    dark: {
      default: colorPrimitives.black50,
      hover: colorPrimitives.black70,
      pressed: colorPrimitives.black80,
      disabled: colorPrimitives.black20,
    },
    outline: {
      default: colorPrimitives.white10,
      hover: colorPrimitives.white15,
      pressed: colorPrimitives.white10,
      disabled: colorPrimitives.white5,
    },
  },
  form: {
    default: colorPrimitives.white10,
    active: colorPrimitives.white20,
    borderFocus: colorPrimitives.white25,
  },
}
