import type { ReactNode } from 'react'
import styled from 'styled-components'
import { Button } from '../button/button/button'
import { Icon } from '../icon/icon/icon'
import { Stack } from '../layout/stack-redux/stack'
import type { TextColors } from '../typography/typography-text/typography-text'
import { TypographyText } from '../typography/typography-text/typography-text'

type Props = {
  title: string
  titleColor?: TextColors
  packageName: string
  description?: string | ReactNode | null
  cta?: string
  expireAt?: string
  onClick?: () => void
  onRemove?: () => void
}

export const Deal = ({ title, titleColor, packageName, description, cta, expireAt, onClick, onRemove }: Props) => {
  return (
    <Container nwGap={3}>
      <Stack nwDirection="row" nwJustifyContent="space-between" nwAlignItems="flex-end">
        <TypographyText nwVariant="title3" nwColor={titleColor}>
          {title}
        </TypographyText>
        {onRemove && <Icon nwVariant="close-x-filled" nwClickable onClick={onRemove} />}
      </Stack>
      <Stack nwGap={2} nwDirection="row" nwJustifyContent="space-between" nwAlignItems="flex-end">
        <Stack nwGap={3}>
          <TypographyText nwVariant="info">{packageName}</TypographyText>
          {description && (
            <TypographyText nwVariant="info" nwColor="secondary">
              {description}
            </TypographyText>
          )}
          {expireAt && (
            <TypographyText nwVariant="cardDetailMeta" nwColor="tertiary">
              {expireAt}
            </TypographyText>
          )}
        </Stack>
        {cta && (
          <Button nwVariant="secondary" onClick={onClick}>
            {cta}
          </Button>
        )}
      </Stack>
    </Container>
  )
}

const Container = styled(Stack)(({ theme }) => ({
  border: '1px dashed',
  borderColor: theme.color.border['25'],
  borderRadius: theme.radii.border_radius,
  padding: theme.space(4),
  backgroundColor: theme.color.surface.black50,
}))
