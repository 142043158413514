import type { QueryHookOptions } from '@apollo/client'
import type { SubscriptionStateQuery, SubscriptionStateQueryVariables } from '@nordic-web/gql'
import { useSubscriptionStateQuery } from '@nordic-web/gql'
import { useAuthenticationStore } from '@/features/auth/authentication-store'

export function useSubscriptionState(
  options: QueryHookOptions<SubscriptionStateQuery, SubscriptionStateQueryVariables> = {}
) {
  const { isLoggedIn } = useAuthenticationStore()

  const {
    data,
    loading: isLoading,
    ...rest
  } = useSubscriptionStateQuery({
    fetchPolicy: 'network-only',
    skip: !isLoggedIn,
    ...options,
  })

  return { subscriptionState: data?.user?.subscriptionState, isSubscriptionStateLoading: isLoading, ...rest }
}
