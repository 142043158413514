import { nonNullable } from '@nordic-web/utils/typescript'
import { brandConfig } from '@/config/brand'

export function formatPrice(price: number): string
export function formatPrice(price?: number | null): string | undefined

export function formatPrice(price?: number | null): string | undefined {
  if (!nonNullable(price)) return

  const isInteger = Number.isInteger(price)

  const options = isInteger
    ? { minimumFractionDigits: 0, maximumFractionDigits: 0 }
    : { minimumFractionDigits: 2, maximumFractionDigits: 2 }

  return price.toLocaleString(brandConfig.language, options)
}
