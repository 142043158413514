import type { ReactNode } from 'react'
import styled from 'styled-components'

type Alignment = 'start' | 'center'

type FormValidationMessageProps = {
  nwMessage?: string | ReactNode
  nwTextAlign?: Alignment
  className?: string
}

/**
 * Validation message to be used inside forms. This is already built-in to FormInput, so you don't need to use it there.
 * NOTE: Don't conditionally render this component, as it will cause issues with screen readers. Instead, just coditionally render
 * the `nwMessage` prop
 */
export const FormValidationMessage = (props: FormValidationMessageProps) => {
  return (
    <Wrapper
      aria-atomic="true"
      role="alert"
      alignment={props.nwTextAlign}
      hasMessage={!!props.nwMessage}
      className={props.className}
    >
      {props.nwMessage && <span>{props.nwMessage}</span>}
    </Wrapper>
  )
}

const Wrapper = styled.div<{ alignment?: Alignment; hasMessage?: boolean }>(({ theme, alignment, hasMessage }) => ({
  ...theme.typography.body3,
  color: theme.color.text.error,
  ...(hasMessage && { paddingBlockStart: theme.space(1) }),
  ...(alignment === 'center' && { textAlign: 'center' }),
}))
