import { useTranslations } from 'next-intl'
import type { ParentalRatingFieldsFragment } from '@nordic-web/gql'
import { MtvParentalRating } from '@/components/mtv-parental-rating'
import { Tv4AgeRating } from '@/components/tv4-age-rating'
import { MetaPropertyList } from '.'

type StandardMetaPropertyListProps = {
  parentalRating?: ParentalRatingFieldsFragment | null
  genres?: string[]
  productionYear?: string | null
  league?: string | null
  round?: string | null
  compact?: boolean
  oneLine?: boolean
  numberOfAvailableSeasons?: number
  series?: {
    genres?: string[]
  } | null
  __typename?: string
  duration?: { readableShort: string } | null
  inStudio?: string | null
  genre?: string | null
}

export const StandardMetaPropertyList = ({
  parentalRating,
  genres,
  productionYear,
  league,
  round,
  numberOfAvailableSeasons,
  series,
  compact = false,
  oneLine = false,
  __typename,
  duration,
  inStudio,
  genre,
}: StandardMetaPropertyListProps) => {
  const t = useTranslations()
  const mediaGenres = series?.genres ?? genres

  return (
    <MetaPropertyList compact={compact} oneLine={oneLine}>
      {typeof parentalRating?.sweden?.ageRecommendation === 'number' && (
        <Tv4AgeRating ageRating={parentalRating.sweden.ageRecommendation} />
      )}
      {parentalRating?.finland && <MtvParentalRating {...parentalRating.finland} height={compact ? 20 : 24} />}
      {productionYear && __typename === 'Movie' && <div>{productionYear}</div>}
      {!!mediaGenres?.length && <div>{mediaGenres.join(', ')}</div>}
      {genre && <div>{genre}</div>}
      {duration && __typename === 'Movie' && <div>{duration.readableShort}</div>}
      {league && <div>{league}</div>}
      {round && <div>{round}</div>}
      {inStudio && <div>{t('general__studio')}</div>}
      {numberOfAvailableSeasons && numberOfAvailableSeasons > 0 ? (
        <div>{t('general__number_of_seasons', { number: numberOfAvailableSeasons })}</div>
      ) : null}
    </MetaPropertyList>
  )
}
