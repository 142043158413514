import { createGlobalStyle } from 'styled-components'

export const forceHideWebviewBanners = 'force-hide-webview-banners'

export const ForceHideWebviewBannersStyle = createGlobalStyle`
  .${forceHideWebviewBanners}  {
    #onetrust-consent-sdk,
    .service-messages {
      display: none!important;
    }
  }
`
