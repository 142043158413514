import { useLogoutAllMutation, useLogoutMutation } from '@nordic-web/gql'
import { logout as logoutAllInternal } from '@/features/auth/authentication-store'
import { AuthTracking } from '@/features/auth/tracking-events'
import { useUserQuery } from '@/features/user/hooks/use-user-query'
import { getPageName } from '@/helpers/get-page-name'

export function useLogout() {
  const { user } = useUserQuery()

  function onRequestComplete() {
    AuthTracking.onLogoutSuccess(user?.details.id ?? '', getPageName() ?? '')
    logoutAllInternal()
  }
  const [logout, { loading: isLoggingOut, error: logoutError }] = useLogoutMutation({
    onCompleted: onRequestComplete,
    onError: onRequestComplete,
  })
  const [logoutAll, { loading: isLoggingOutAll, error: logoutAllError }] = useLogoutAllMutation({
    onCompleted: onRequestComplete,
    onError: onRequestComplete,
  })

  return { logout, logoutAll, isLoggingOut, isLoggingOutAll, logoutError, logoutAllError }
}
